// Import types
import type { IPlayerItemProps } from "../../utils/types";
// Import libraries and functions
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import urls
import { api_urls } from "../../utils/config";
import { faCrosshairs, faInfoCircle, faMale, faShoePrints, faWeight } from "@fortawesome/free-solid-svg-icons";

const PlayerItem = ({ player, player_status, showAvailability, onChangeStatus, onDelete }: IPlayerItemProps) => {
   return (
      <div
         className={`player ${showAvailability ? "showAvailability" : ""} ${
            player.current_team === "" || !player.current_team ? "available" : "not-available"
         }`}
      >
         <img src={api_urls.getImage + player.image?.replaceAll("/", "slash")} alt="player" />
         <Link to={`/player/${player._id}`} className="player__info-btn">
            <FontAwesomeIcon icon={faInfoCircle} />
         </Link>
         <p className="player__name">{player.name}</p>
         <p className="player__surname">
            {player.surname}, {player.age}
         </p>
         <div className="player__measures__caracteristics">
            <div className="player__measures">
               <p className="player__measures-height">
                  <FontAwesomeIcon icon={faMale} />
                  {player.height.toFixed(2)}
               </p>
               <p className="player__measures-weight">
                  <FontAwesomeIcon icon={faWeight} />
                  {player.weight.toFixed(2)}
               </p>
            </div>
            <div className="player__caracteristics">
               <p className="player__caracteristics-laterality">
                  <FontAwesomeIcon icon={faShoePrints} />
                  {player.laterality}
               </p>
               <p className="player__caracteristics-position">
                  <FontAwesomeIcon icon={faCrosshairs} />
                  {player.position}
               </p>
            </div>
         </div>

         <div className="player__options">
            {player_status === "preselected" ? (
               <button className="btn btn-transparent" onClick={() => onChangeStatus(player, "return")}>
                  Devolver
               </button>
            ) : player_status === "predeleted" ? (
               <>
                  <button className="btn btn-transparent" onClick={() => onChangeStatus(player, "return")}>
                     Devolver
                  </button>
                  <button className="btn btn-orange" onClick={() => (onDelete ? onDelete(player) : null)}>
                     Eliminar
                  </button>
               </>
            ) : (
               <>
                  <button className="btn btn-transparent" onClick={() => onChangeStatus(player, "predeleted")}>
                     Eliminar
                  </button>
                  <button className="btn btn-orange" onClick={() => onChangeStatus(player, "preselected")}>
                     Seleccionar
                  </button>
               </>
            )}
         </div>
      </div>
   );
};

export default PlayerItem;
