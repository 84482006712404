import { IApiUrls } from "./types";

const api_urls: IApiUrls = {
   getPlayers: "/api/players/get",
   getPlayer: "/api/players/get/",
   getPredeletedPlayers: "/api/players/predeleted",
   getPreselectedPlayers: "/api/players/preselected",
   getImage: "/api/players/image/",
   changeStatus: "/api/player/set/",
   deletePlayer: "/api/player/delete/",
   registerPlayer: "/api/player/save/",
   uploadImage: "/api/player/postImage/",
};

export { api_urls };
